import React from "react"
import { Routes } from "../../data/routes"
import CTA from "../../components/CTA"
import Img from "../../components/img"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const Content = {
  pageName: "Reviews for Yup's math tutoring service",
  header: "Student success stories, straight from our inbox",
  description: "Student success stories, straight from our inbox",
  cover: "reviews-cover.png",

  // Reviews section
  reviews: [
    {
      review: `It has given her so much more confidence`,
      additional: `My daughter has always struggled with math throughout grade school and in to
        JH. We decided to give Yup a try to see if we could improve her grades
        and her confidence for her freshman year. I can't tell you what an amazing
        experience it has been! She went from a C-D average to being a A-B student!!
        It has given her so much more confidence. Her being able to understand her
        homework is worth every cent!

        I'm so glad we found Yup! The ease of access for her to be able to use it whenever
        she needs it is such an amazing benefit! And not only the tutors but the
        people at Yup care about making it the best experience for her! Thank you
        for this amazing opportunity for her! We love Yup and will continue to use it
        through out her HS years!`,
      name: "Mandy A.",
      role: "Mom to 9th Grader Macie",
      location: "Giltner, NE",
      image: "reviews-1.jpeg",
    },
    {
      review: `Love that my daughter has access 24/7. With school activities she isn't
                always doing homework at conventional times. The tutors have all been great.`,
      name: "Amanda W.",
      role: "Mom to 9th Grader Yanna",
      location: "Omaha, NE",
    },
    {
      review: `Such a huge improvement`,
      additional: `Yup has given our son the on-demand tutoring and help with math that he has
        needed going on our 2nd year. Having the patient and at home guidance that this
        app and its tutors provide has helped raised his math grade substantially and
        improved his confidence with the subject tremendously. We have never experienced
        any glitches in the app and it has always works quickly and efficiently.`,
      name: "Rebekah G.",
      role: "Mom to 8th Grader Blake",
      location: "Valencia, CA",
      image: "reviews-2.jpeg",
    },
    {
      review: `I've seen a significant improvement from my son and that made it easier for the
                whole family! No more attitude when working on his homework cause of help he is getting
                from Yup. Thanks`,
      name: "Grace H.",
      role: "Mom to 10th Grader Resty",
      location: "Marysville, WA",
    },
    {
      review: `Yup eliminates a lot of her anxiety`,
      additional: `My daughter is struggling in math, turns out she has autism and she's so high functioning
        she wasn't even diagnosed until she was 14. But written instruction works better with
        her than verbal commands. Yup has been nothing but a complete blessing because she
        doesn't have the anxiety of dealing with face to face tutoring. Or the anxiety of
        seeing videos. Yup eliminates a lot of her anxiety.

        I love that you have the notes on your website [family portal] and I can see how she's
        interacting with her [tutors]. And how they aren't giving her the answers but are
        helping her develop to the answer.`,
      name: "Angela P.",
      role: "Mom to 8th Grader Amanda",
      location: "Fullerton, ND",
      image: "reviews-3.jpeg",
    },
  ],

  // CTA section
  cta: "Want to learn what Yup can do for your school?",
  ctaActions: [
    {
      link: Routes.schools,
      text: "Learn More",
    },
  ],
}

function Header() {
  // Original TailwindUI component: https://tailwindui.com/components/marketing/sections/header#component-17d3a4a8538b7d528d954a5db2874da8
  return (
    <div className="relative">
      <div className="absolute inset-0">
        <Img
          className="w-full h-full"
          src={Content.cover}
          alt="Blue background"
          cover={true}
        />
      </div>
      <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl">
          {Content.header}
        </h1>
      </div>
    </div>
  )
}

type ReviewProps = {
  review: string
  name: string
  role: string
  location: string
  additional?: string
  image?: string
}
function Review(props: ReviewProps) {
  // Original TailwindUI component: https://tailwindui.com/components/marketing/sections/testimonials#component-54ee9cbce13afa3082f12b00ff1c00a6
  return (
    <section className="overflow-hidden">
      <div className="relative max-w-7xl mx-auto pt-20 pb-12 px-4 sm:px-6 lg:px-8 lg:py-20">
        <svg
          className="absolute top-full left-0 transform translate-x-80 -translate-y-24 lg:hidden"
          width={784}
          height={404}
          fill="none"
          viewBox="0 0 784 404"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width={784}
            height={404}
            fill="url(#e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32)"
          />
        </svg>

        <svg
          className="hidden lg:block absolute right-full top-1/2 transform translate-x-1/2 -translate-y-1/2"
          width={404}
          height={784}
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="56409614-3d62-4985-9a10-7ca758a8f4f0"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={784}
            fill="url(#56409614-3d62-4985-9a10-7ca758a8f4f0)"
          />
        </svg>

        <div className="relative lg:flex lg:items-center">
          <div className="hidden lg:block lg:flex-shrink-0 w-64 xl:w-80">
            {props.image && (
              <Img
                className="h-64 w-64 rounded-full xl:h-80 xl:w-80"
                src={props.image}
                alt={props.name}
                cover={true}
              />
            )}
          </div>

          <div className="relative lg:ml-10">
            <svg
              className="absolute top-0 left-0 transform -translate-x-8 -translate-y-24 h-36 w-36 text-secondary-100 opacity-50"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 144 144"
              aria-hidden="true"
            >
              <path
                strokeWidth={2}
                d="M41.485 15C17.753 31.753 1 59.208 1 89.455c0 24.664 14.891 39.09 32.109 39.09 16.287 0 28.386-13.03 28.386-28.387 0-15.356-10.703-26.524-24.663-26.524-2.792 0-6.515.465-7.446.93 2.327-15.821 17.218-34.435 32.11-43.742L41.485 15zm80.04 0c-23.268 16.753-40.02 44.208-40.02 74.455 0 24.664 14.891 39.09 32.109 39.09 15.822 0 28.386-13.03 28.386-28.387 0-15.356-11.168-26.524-25.129-26.524-2.792 0-6.049.465-6.98.93 2.327-15.821 16.753-34.435 31.644-43.742L121.525 15z"
              />
            </svg>
            <blockquote className="relative">
              <div className="text-2xl leading-9 font-medium text-gray-900">
                <p>{props.review}</p>
              </div>
              {(props.additional ?? "").split(/\n\n/g).map(additional => (
                <p className="text-gray-500 pt-4" key={additional}>
                  {additional}
                </p>
              ))}
              <footer className="mt-8">
                <div className="flex">
                  <div className="flex-shrink-0 lg:hidden">
                    {props.image && (
                      <Img
                        className="h-12 w-12 rounded-full"
                        src={props.image}
                        alt={props.name}
                        cover={true}
                      />
                    )}
                  </div>
                  <div className="ml-4 lg:ml-0">
                    <div className="text-base font-medium text-gray-900">
                      {props.name}
                    </div>
                    <div className="text-base font-medium text-secondary">
                      {props.role}
                    </div>
                    <div className="text-base font-medium text-secondary">
                      {props.location}
                    </div>
                  </div>
                </div>
              </footer>
            </blockquote>
          </div>
        </div>
      </div>
    </section>
  )
}

function ReviewSection() {
  return (
    <div className="max-w-7xl mx-auto py-4">
      {Content.reviews.map(review => (
        <Review {...review} key={review.name} />
      ))}
    </div>
  )
}

const Reviews = () => {
  return (
    <Layout>
      <Seo
        title={Content.pageName}
        description={Content.description}
        route={Routes.reviews}
      />
      <Header />
      <ReviewSection />
      <CTA title={Content.cta} actions={Content.ctaActions} />
    </Layout>
  )
}

export default Reviews
